import { useState, useEffect, useMemo } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import "./index.css";

export const Fashion = () => {
  const [data, setData] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const builder = useMemo(() => ImageUrlBuilder(sanityClient), []);
  const urlFor = useMemo(() => {
    return (source) => builder.image(source);
  }, [builder]);

  const allImagesLoaded = () => {
    setImagesLoaded((prevLoaded) => prevLoaded + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == 'fashion'][0]`;
        const result = await sanityClient.fetch(query);
        setData(result);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section className="fashion-page">
        <artcle className="intro-paragraph">
          {data && data.introParagraph && <h1>{data.introParagraph}</h1>}
        </artcle>
        <h1>Shows</h1>
        {imagesLoaded !== data.showListings?.length && (
          <h1 className="loading-text">{imagesLoaded} Shows loaded </h1>
        )}
        <section
          className={`show-listings ${
            imagesLoaded === data.showListings?.length ? "loaded" : ""
          }`}
        >
          {data &&
            data.showListings &&
            data.showListings.map((show, index) => {
              return (
                <div className="show" index={index}>
                  <a href={`/fashion/${show.title}`}>
                    <div className="cover">
                      <img
                        src={urlFor(show.thumbnail.asset._ref).url()}
                        onLoad={() => {
                          allImagesLoaded();
                        }}
                        loading="lazy"
                      />
                      <div className="overlay">
                        <h2>{show.title}</h2>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
        </section>
      </section>
    </>
  );
};
