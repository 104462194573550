import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import sanityClient from "../../client";

import { renderText, renderMedia } from "../../functions";

import "./index.css";

export const Show = () => {
  const { pathname } = useLocation();
  const pathParts = pathname.split("/");
  const showTitleFromPathname = decodeURIComponent(
    pathParts[pathParts.length - 1]
  );

  const [data, setData] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [theme, setTheme] = useState([]);
  const [roles, setRoles] = useState([]);

  const applyTheme = () => {
    if (theme) {
      theme.forEach((themeValue) => {
        if (themeValue !== undefined) {
          const bodyEl = theme.length > 0 ? document.body : null;
          const strings =
            theme.length > 1 ? document.querySelectorAll("h1, p, h2") : null;
          const links =
            theme.length > 2
              ? document.querySelectorAll(".individual-fashion-page a")
              : null;
          bodyEl.style.backgroundColor = theme[0].value;

          strings.forEach((string) => {
            string.style.color = theme[1].value;
          });

          links.forEach((link) => {
            link.style.color = `${theme[2].value}`;
          });
        }
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == 'fashion'][0]`;
        const result = await sanityClient.fetch(query);
        result.showListings.forEach((show) => {
          if (show.title.includes(showTitleFromPathname)) {
            setBlocks(show.individualFashionPageContent.blocks || []);
            setTheme(show.individualFashionPageContent.theme || []);
            setData(show || []);
            setRoles(show.individualFashionPageContent.roles || []);
          }
        });

        applyTheme();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [theme]);

  return (
    <>
      <section className="individual-fashion-page">
        <h1 className="header-title">{data.title && data.title}</h1>
        <div className="cover">
          {blocks &&
            blocks.map((block) => {
              if (block.cover) {
                return (
                  <div className="cover-image" key={block._key}>
                    {renderMedia(block)}
                  </div>
                );
              }
              return null;
            })}
          {blocks.map((block, blockIndex) => {
            if (block.textBlock !== undefined && block.textBlock[0]?.children) {
              return (
                <div className="cover-caption" key={blockIndex}>
                  {renderText(block)}
                </div>
              );
            }
            return null;
          })}
        </div>
        <div className="gallery">
          <div className="item">
            {blocks &&
              blocks.map((block, blockIndex) => {
                if (!block.cover) {
                  return (
                    <div key={blockIndex}>
                      {renderMedia(block)}{" "}
                      {block.caption && <p>{block.caption}</p>}
                    </div>
                  );
                }
                return null;
              })}
            <div className="roles">
              {roles && (
                <ul>
                  {roles.map((role) => {
                    return role.link ? (
                      <li key={role._key}>
                        <span>{role.role}: </span>
                        <a href={role.link}>{role.name}</a>
                      </li>
                    ) : (
                      <li key={role._key}>{role.name}</li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Show;
