import { useEffect, useState } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from '@sanity/image-url';

import { ContactContainer } from "../ContactContainer";

import './index.css';

export const Header = () => {
    const [navigation, setNavigation] = useState([]);
    const [logos, setLogos] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visible, setVisible] = useState(true);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [showLabel, setShowLabel] = useState(false);
    const [oepnContact, setOpenContact] = useState(false);

    const builder = ImageUrlBuilder(sanityClient);
    const urlFor = (source) => {
      return builder.image(source);
    }

    const handleMouseEnter = (e) => {
        setShowLabel(true);
        document.getElementById('item-label').innerText = e.target.id;
    }

    const handleMouseLeave = () => {
        setShowLabel(false);
    }

    const fetchData = async () => {
        try {
            const query = `*[_type == 'header'][0]`;
            const result = await sanityClient.fetch(query);
            setNavigation(result.navigation);
            setLogos(result.logos);
        } catch (error) {   
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        let interval;

        if (logos.length > 0) {
            interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
            }, 2000);
        }

        return () => clearInterval(interval);
    }, [logos]);

    useEffect(() => {
        const handleMouseMove = (e) => {
          setPosition({ x: e.clientX, y: e.clientY });
        };
    
        document.addEventListener('mousemove', handleMouseMove);
    
        return () => {
          document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <>
            <header className={`header ${visible ? '' : 'hide'}`}>
                {logos && logos.length > 0 && logos[currentIndex] && (
                    <div className="logo-container">
                        <a href='/'>
                            <img src={urlFor(logos[currentIndex].asset._ref).url()} />
                        </a>
                    </div>
                )}
                {navigation && navigation.length > 0 && (
                    <nav className="navigation">
                        {navigation.map((item) => {
                            return (
                                item.title !== 'Contact' ? (
                                    <a key={item._key} href={`/${item.title.toLowerCase()}`}>
                                        <img 
                                            id={item.title} 
                                            src={urlFor(item.icon.asset._ref).url()}
                                            onMouseEnter={(e) => handleMouseEnter(e)}
                                            onMouseLeave={handleMouseLeave}
                                        />
                                        <p 
                                            id='item-label' 
                                            style={{top: position.y + 20, left: position.x - 35}}
                                            className={`${showLabel ? 'show' : ''}`}
                                        ></p>
                                    </a>
                                ) : (
                                    <a key={item._key} href='#contact' onClick={() => setOpenContact(!oepnContact)}>
                                        <img 
                                            id={item.title} 
                                            src={urlFor(item.icon.asset._ref).url()}
                                            onMouseEnter={(e) => handleMouseEnter(e)}
                                            onMouseLeave={handleMouseLeave}
                                        />
                                        <p 
                                            id='item-label' 
                                            style={{top: position.y + 20, left: position.x - 35}}
                                            className={`${showLabel ? 'show' : ''}`}
                                        ></p>
                                    </a>  
                                )
                            )
                        })}
                    </nav>
                )}
            </header>
            <ContactContainer openContact={oepnContact} setOpenContact={setOpenContact}/>
        </>
    )
}