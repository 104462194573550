import { useEffect, useState, useMemo } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import { Slider } from "../../Components/Slider";

import "./index.css";

export const Projects = () => {
  const [data, setData] = useState([]);
  const [openProjectIndex, setOpenProjectIndex] = useState(null);
  const [width] = useState(window.innerWidth);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const builder = useMemo(() => ImageUrlBuilder(sanityClient), []);
  const urlFor = useMemo(() => {
    return (source) => builder.image(source);
  }, [builder]);

  const hideDropDown = (e) => {
    if (e.target.id === "dropdown-container") {
      setOpenProjectIndex(null);
    }
  };

  const allImagesLoaded = () => {
    setImagesLoaded((prevLoaded) => prevLoaded + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == 'projects']`;
        const result = await sanityClient.fetch(query);
        setData(result);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    document.title = "PROJECTS";
  }, []);

  return (
    <>
      <section className="projects-page">
        {imagesLoaded !== data.length && (
          <h1 className="loading-text">{imagesLoaded} Projects loaded </h1>
        )}
        {imagesLoaded === data.length && (
          <h1 className="title">Select Projects</h1>
        )}
        <div
          className={`projects-container ${
            imagesLoaded === data.length ? "loaded" : ""
          }`}
        >
          {data && data.length > 0 ? (
            data
              .slice()
              .sort((a, b) => a.project_order_number - b.project_order_number)
              .map((project) => {
                const isDropdownOpen =
                  openProjectIndex === project.project_order_number;
                return (
                  <>
                    <div
                      className={`project ${isDropdownOpen ? "active" : ""}`}
                      index={project.project_order_number}
                    >
                      <div className="cover">
                        <img
                          src={urlFor(project.project_cover.asset._ref).url()}
                          loading="lazy"
                          onLoad={() => {
                            allImagesLoaded();
                          }}
                        />
                        <div
                          className="overlay"
                          onClick={() =>
                            setOpenProjectIndex(
                              isDropdownOpen
                                ? null
                                : project.project_order_number
                            )
                          }
                        >
                          <h2>{project.project_subheading}</h2>
                        </div>
                      </div>
                    </div>
                    {isDropdownOpen && (
                      <div
                        id="dropdown-container"
                        className={`dropdown-container show`}
                        onClick={(e) => hideDropDown(e)}
                      >
                        {width <= 768 && (
                          <h1
                            className="return-button"
                            onClick={(e) => setOpenProjectIndex(null)}
                          >
                            RETURN
                          </h1>
                        )}
                        <div className="dropdown-content">
                          <h1>{project.project_title}</h1>
                          <h2>
                            {project.project_subheading} -{" "}
                            {project.project_year}
                          </h2>
                          <Slider media={project.project_media} />
                          <div className="description">
                            <h3>{project.project_description}</h3>
                            <ul>
                              {project.project_roles &&
                                project.project_roles.map((role, index) => {
                                  return <li key={index}>{role}</li>;
                                })}
                            </ul>
                          </div>
                          <a href={project.project_link}>View</a>
                        </div>
                      </div>
                    )}
                  </>
                );
              })
          ) : (
            <h1>Loading</h1>
          )}
        </div>
      </section>
    </>
  );
};
