import { useState, useEffect } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from '@sanity/image-url';

import './index.css';

export const Gallery = () => {
    useEffect(() => {
        document.title = 'ABOUT';
    }, []);

    return (
        <>
            <section className='gallery-page'>
                <h1>UNDER CONSTRUCTION</h1>
            </section>
        </>
    )
}