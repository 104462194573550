import React from "react";
import ImageUrlBuilder from "@sanity/image-url";
import sanityClient from "./client";

export const builder = ImageUrlBuilder(sanityClient);

export const urlFor = (source) => builder.image(source);

export const videoConversion = (assetRef) => {
  const withoutFilePrefix = assetRef.replace("file-", "");
  const modifiedAssetRef = withoutFilePrefix.replace(/-(?=[^-]*$)/, ".");
  const mutatedRef = `https://cdn.sanity.io/files/ufskghtn/production/${modifiedAssetRef}`;

  return (
    <video className="lightbox-video" autoPlay loop muted controls={false}>
      <source src={mutatedRef} type="video/mp4" />
    </video>
  );
};

export const renderText = (block) => {
  if (block) {
    const textBlocks = block.textBlock.map((textBlockItem, index) => {
      const childrenElements = textBlockItem.children.map(
        (child, childIndex) => {
          const hasLink =
            child.marks &&
            child.marks.some((mark) =>
              textBlockItem.markDefs.some(
                (def) => def._key === mark && def._type === "link"
              )
            );
          const linkDef = hasLink
            ? textBlockItem.markDefs.find((def) => def._key === child.marks[0])
            : null;
          return hasLink && linkDef ? (
            <a
              key={childIndex}
              href={linkDef.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {child.text}
            </a>
          ) : (
            <span key={childIndex}>{child.text}</span>
          );
        }
      );

      return <h2 key={index}>{childrenElements}</h2>;
    });

    return <>{textBlocks}</>;
  }
  return null;
};

export const renderMedia = (block) => {
  if (block.media === "image" && block.image && block.image.asset) {
    return <img src={urlFor(block.image.asset._ref).url()} alt="" />;
  } else if (block.media === "video" && block.video && block.video.asset) {
    return videoConversion(block.video.asset._ref);
  }
  return null;
};
