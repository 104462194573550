import { useEffect, useState } from "react";
import sanityClient from "../../client";
import './index.css';

export const ContactContainer = ({openContact, setOpenContact}) => {
    const [data, setData] = useState([]);
    const [hideContact, setHideContact] = useState(false)

    const fetchData = async () => {
        try {
            const query = `*[_type == 'contact'][0]`;
            const result = await sanityClient.fetch(query);
            setData(result);
        } catch (error) {   
            console.error(error);
        }
    };

    const handleOuterClick = (e) => {
        if (e.target.className.includes('contact-container') && e.target.className.includes('show')) {
            setOpenContact(false);
            setHideContact(true);
        } else if (e.target.className.includes('contact-container') && e.target.className.includes('show')) {
            setHideContact(false);
            setOpenContact(true);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div 
                className={`contact-container ${openContact ? 'show' : ''}`}
                onClick={(e) => handleOuterClick(e)}
            >
                <div className='contact-info'>
                    {data && data.email && data.instagram && (
                        <>
                            <h1>
                                <a href={`mailto:${data.email}`}>{data.email}</a>
                            </h1>
                            <h1>
                                <a href={`https://www.instagram.com/${data.instagram.replace('@', '')}`}>{data.instagram}</a>
                            </h1>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}